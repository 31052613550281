@media print {
  body{
    background-color: #ffffff;
  }
  .print-50{
    width: 50%;
    text-align: center;
    margin: 100px auto auto;
    .print-content{
      text-align: left;
      color: #000 !important;
      .card{
        .card-header, .input-group-text, .alert, .payment{
          font-weight: bold;
        }
        border-color: #000;
        .input-group-text, .form-control, .bg-info, td, label, .alert{
          color: #000000;
        }
      }
    }
  }
}

.form-check-input{
  position: relative;
  margin-top: 10px;
  margin-left: 10px;
}

.form-check-label{
  margin-left: 10px;
  margin-top: 5px;
}

.menu-buy{
  i{
    font-size: 20px;
  }
}
.app-header{
  .nav-item {
    min-width: 45px;
  }
}
.card-header-actions {
  .EmployeesListComponent{
    min-width: 180px;
    z-index: 9;
    display: inline-block;
  }
}
.video-responsive{
  position: relative;
  padding-bottom: 56.25%; /* 16/9 ratio */
  padding-top: 30px; /* IE6 workaround*/
  height: 0;
  overflow: hidden;
}
.video-responsive iframe,
.video-responsive object,
.video-responsive embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.form-row{
  .full-width{
    flex: auto;
  }
  &.services-input{
    .input-group{
      flex-wrap: nowrap;
    }
  }
}

.toast{
  &.toast-success {
    background-color: #51A351;
  }
  &.toast-error {
    background-color: #BD362F;
  }
  &.toast-info {
    background-color: #2F96B4;
  }
  &.toast-warning {
    background-color: #F89406;
  }
}

.grid-image-cell{
  max-width: 200px;
  max-height: 100px;
}

.image-crop-preview{
  display: none;
}

.form-image-crop{
  .input-group{
    flex-flow: row;
    .no-picture{
      font-size: 150px;
      color: #adb5bd;
      margin-left: 20px;
    }
    img{
      max-width: 200px;
      max-height: 200px;
    }
  }
}

.select-custom-option-image{
  .option{
    display: flex;
    align-items: center;
    padding: 0 12px;
    min-height: 35px;
  }
  display: flex;
  .select-option-image{
    max-height: 60px;
    max-width: 60px;
    font-size: 30px;
  }

  .select-option-label{
    margin-left: 5px;
  }
}

.filters{
  .react-datepicker-wrapper{
    .form-control{
      height: 38px;
    }
  }
}

.react-datepicker-popper{
  .react-datepicker__time-container{
    width: 90px;
    .react-datepicker__time-box{
      width: 90px;
    }
  }
}

.rich-text-wrapper{
  flex: auto;
  div{
    z-index: initial;
  }
  .public-DraftEditor-content{
    min-height: 110px;
  }

}

.react-datepicker-wrapper{
  flex: auto;
  .react-datepicker__input-container{
    display: flex;
    button{
      text-align: left;
    }
  }
}

.responsiveTable {
  .btn{
    margin-bottom: 5px;
  }
  .td-image.pivoted{
    padding-left: 0 !important;
    .tdBefore{
      display: none !important;
    }
  }
  pre{
    white-space: pre-wrap;
  }
  .sortable {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAAkElEQVQoz7X QMQ5AQBCF4dWQSJxC5wwax1Cq1e7BAdxD5SL+Tq/QCM1oNiJidwox0355mXnG/DrEtIQ6azioNZQxI0ykPhTQIwhCR+BmBYtlK7kLJYwWCcJA9M4qdrZrd8pPjZWPtOqdRQy320YSV17OatFC4euts6z39GYMKRPCTKY9UnPQ6P+GtMRfGtPnBCiqhAeJPmkqAAAAAElFTkSuQmCC");
    cursor: pointer;
    background-position: right;
    background-repeat: no-repeat;
    &.asc{
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZ0lEQVQ4y2NgGLKgquEuFxBPAGI2ahhWCsS/gDibUoO0gPgxEP8H4ttArEyuQYxAPBdqEAxPBImTY5gjEL9DM+wTENuQahAvEO9DMwiGdwAxOymGJQLxTyD+jgWDxCMZRsEoGAVoAADeemwtPcZI2wAAAABJRU5ErkJggg==);
    }
    &.desc{
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZUlEQVQ4y2NgGAWjYBSggaqGu5FA/BOIv2PBIPFEUgxjB+IdQPwfC94HxLykus4GiD+hGfQOiB3J8SojEE9EM2wuSJzcsFMG4ttQgx4DsRalkZENxL+AuJQaMcsGxBOAmGvopk8AVz1sLZgg0bsAAAAASUVORK5CYII=);
    }
  }
  .row-editor{
    cursor: pointer;
    input{
      max-width: 90px;
    }
    .ProductCategoryListComponent, .ProductBrandListComponent{
      min-width: 150px;
    }
  }
}

.date-range-filter{
  .input-group{
    > div{
      width: 100%;
      .react-datepicker__input-container{
        display: block;
      }
    }

  }
}

.required.input-group-prepend{
  span{
    &::after{
      content: "*";
      padding-left: 2px;
    }
  }
}

.color-picker-widget{
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1);
  display: inline-block !important
}

.rbc-calendar{
  .rbc-month-view{
    .rbc-row-content{
      z-index: 0;
    }
  }
  .rbc-agenda-view{
    .rbc-agenda-table{
      .rbc-agenda-event-cell{
        background-color: initial !important;
        color: #000;
        p{
          align-items: center;
          &.employee{
            display: flex;
          }
        }
        .event-data{
          a{
            color: #000000;
          }
          p{
            margin: 0;
          }
        }
        .employee-mark{
          display: inline-block;
          width: 20px;
          height: 20px;
          border-radius: 100px;
          margin-right: 5px;
        }
      }
      .first-date:first-child{
        border-top: none !important;
      }
      .first-date{
        border-top: 1px solid #c8ced3 !important;
      }
    }
  }

  .rbc-agenda-date-cell, .rbc-agenda-time-cell{
    color: #000;
  }
}

@media (max-width: 500px) {
  .pagination{
    .export-text{
      display: none;
    }
  }
  .event-datetime{
    .react-datepicker{
      width: 100%;
    }
    .react-datepicker__month-container{
      width: 75%;
    }
    .react-datepicker__time-container{
      width: 25%;
    }
    .react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name{
      width: 1.5rem;
    }
  }
}

@media screen and (max-width: 40em) {
  .responsiveTable tbody tr {
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .responsiveTable td.pivoted{
    padding-left: 0 !important;
    padding-top: 20px;
  }
  .responsiveTable td{
    &.td-actions{
      .tdBefore{
        display: block !important;
      }
    }
    div{
      position: relative !important;
      display: inline !important;
    }
    .tdBefore{
      top: 0;
      left: 0;
      &::after{
        content: ":";
      }
    }
  }
  .responsiveTable td div.color-picker-widget{
    display: inline-block !important;
    top: 6px;
    div{
      display: block !important;
    }
  }
}

@media (max-width: 575px) {
  .filters.row{
    .col-12{
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 700px) {
  .rbc-calendar{
    .rbc-navigation{
      order: 2;
      flex-grow: 1;
    }
    .rbc-toolbar-label{
      order: 1;
      width: 100%;
      font-size: 15px;
      padding: 0;
    }
    .rbc-views{
      order: 3;
    }
  }
}

@media (max-width: 450px) {
  .rbc-calendar{
    .rbc-navigation{
      flex-grow: inherit;
      align-items: center;
      justify-content: center;
      margin: 10px 0;
    }
    .rbc-toolbar-label{
      order: 1;
      width: 100%;
    }
    .rbc-views{
      order: 3;
      display: flex;
    }
    .rbc-agenda-view{
      table.rbc-agenda-table{
        thead{
          display: none;
        }
        .rbc-agenda-time-cell{
          padding: 5px 4px;
        }
        .rbc-agenda-date-cell{
          span{
            writing-mode: vertical-rl;
            transform: rotate(-180deg);
            padding: 15px 0;
          }
        }
      }
    }
  }
}

@media (min-width: 576px) {
  .extend-width-800 {
    max-width: 800px;
  }
}
