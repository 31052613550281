div.loading {
  position: fixed;
  display: block;
  width: 100vw;
  height: 100vh;
  cursor: wait;
  z-index: 99999;
  top: 0;
  left: 0;
  text-align: center;
  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #fff;
    opacity: 0.6;
  }
  .label {
    z-index: 999;
    top: 40%;
    font-size: 16px;
    border-radius: 40px;
    padding: 10px;
    white-space: normal;
    max-width: 300px;
    margin: auto;
    line-height: 2;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    background-color: #20a8d8;
    color: #ffffff;
  }
}